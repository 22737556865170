import React from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"

const SectorHub = ({ data: { sectors }, location }) => {
  return (
    <>
      <SEO
        titleOverride={"Sectors We Cover"}
        pathnameOverride={location.pathname}
      />

      <section className="mb-16 lg:mb-32">
        <div className="container">
          <div className="w-full mx-auto mb-8 text-center md:w-2/3 md:mb-0">
            <h1>Sectors we cover</h1>
            <p className="mb-3 text-lg md:mb-5">We have generated leads for clients across a variety of industries. Here are a few examples of some of the sectors we operate within and our most successful projects.</p>

            <p className="mb-8 text-base md:mb-16">Don’t see your industry? Don’t worry; whatever your market, we can help your business grow and encourage you to contact us to discuss your business needs.</p>
          </div>
        </div>
        <div className="container relative z-10">
          <div className="w-full mx-auto md:w-8/12 xl:w-1/2">
            <div className="">
              <nav>
                <ul className="flex flex-wrap justify-center">
                  {sectors.edges.map(({ node }, index) => {
                    return (
                      <li className="block w-full text-center" key={index}><Link to={`/sectors/digital-marketing-${node.slug}`} className="block mb-3 text-lg underline text-secondary md:text-xl">{ node.title }</Link></li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SectorHub

export const query = graphql`
  query sectorHubPageQuery {
    sectors: allDatoCmsSector {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`